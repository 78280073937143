import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  constructor(public auth: AngularFireAuth, private _router: Router) {}

  Login(email, password) {
    this.auth
      .signInWithEmailAndPassword(email, password)
      .then((value) => {
        if (value) {
          this._router.navigate(["/alojamiento"]);
        }
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/wrong-password":
            Swal.fire({
              title: "Ha ocurrido un error",
              html: `<b>El email o la contraseña son incorrectas</b>`,
              icon: "error",
            });
            break;
          case "auth/invalid-email":
            Swal.fire({
              title: "Ha ocurrido un error",
              html: `<b>El email es incorrecto</b>`,
              icon: "error",
            });
            break;
          case "auth/user-not-found":
            Swal.fire({
              title: "Ha ocurrido un error",
              html: `<b>El usuario no existe</b>`,
              icon: "error",
            });
            break;
        }
      });
  }

  logout() {
    this.auth.signOut();
  }
}
