import { Component, OnInit } from "@angular/core";

declare interface RouteInfo {
  path: string;
  title: string;
  rtlTitle: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/alojamiento",
    title: "Alojamientos",
    rtlTitle: "",
    icon: "icon-bank",
    class: "",
  },
  {
    path: "/gastronomia",
    title: "Gastronomía",
    rtlTitle: "",
    icon: "icon-bank",
    class: "",
  },
  {
    path: "/agencias-de-viajes",
    title: "Agencias",
    rtlTitle: "",
    icon: "icon-map-big",
    class: "",
  },
  {
    path: "/transporte-especial-de-turismo",
    title: "Transportes",
    rtlTitle: "",
    icon: "icon-map-big",
    class: "",
  },
  {
    path: "/guias-e-informadores-de-turismo",
    title: "Guías",
    rtlTitle: "",
    icon: "icon-map-big",
    class: "",
  },
  {
    path: "/organizadores-de-eventos",
    title: "Organizadores",
    rtlTitle: "",
    icon: "icon-map-big",
    class: "",
  },
  {
    path: "/atractivos-turisticos",
    title: "Atractivos",
    rtlTitle: "",
    icon: "icon-map-big",
    class: "",
  },
  {
    path: "/reportes",
    title: "Reportes",
    rtlTitle: "",
    icon: "icon-cloud-download-93",
    class: "",
  },
  {
    path: "/formulario-de-contacto",
    title: "Formulario Contacto",
    rtlTitle: "",
    icon: "icon-paper",
    class: "",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
